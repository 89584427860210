import * as dataGridLocales from '@mui/x-data-grid/locales';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { getMuiLanguageCode } from 'src/utils/useFunctions';
import { GridRow } from '@mui/x-data-grid';
import { useStates } from 'src/utils/useState';

interface Props {
  columnHeaderHeight: any;
  isFooterEnabled: any;
};

const useStyles = createUseStyles((theme: any) => ({
  dataTableWrapper: {
    maxWidth: '100%',
    width: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  dataTable: {
    width: '100%',
    maxWidth: '100%',
    borderWidth: '0',
    borderRadius: (_: Props) => '',
    '& .MuiDataGrid-main': {
      width: '100%',
      maxWidth: '100%',
      '& .MuiDataGrid-virtualScroller': {
        width: '100%',
        maxWidth: '100%',
      },
      '& .MuiDataGrid-virtualScrollerRenderZone': {
        width: '100%',
        maxWidth: '100%',
      },
      '& .MuiDataGrid-filler': {
        backgroundColor: theme.colors.white,
      },
      '& .MuiDataGrid-columnHeaders': {
        maxWidth: '100%',
        minHeight: (props: Props) => `${props.columnHeaderHeight}px`,
        '& > div': {
          backgroundColor: '#ECECEC !important',
          borderRadius: '20px 20px 0px 0px',
          minHeight: (props: Props) => `${props.columnHeaderHeight}px`,
          maxWidth: '100%',
        },
      },
      '& .MuiDataGrid-columnHeader': {
        outline: 'none !important',
        fontSize: '12px',
        fontWeight: 'bold',
      },
      '& .MuiDataGrid-row': {
        backgroundColor: theme.colors.white,
        maxWidth: '100%',
        '&:nth-of-type(odd)': {
          backgroundColor: '#F2F2F2',
        },
        '&:hover': {
          backgroundColor: theme.colors.grey[250],
        },
        '&.MuiDataGrid-row--lastVisible': {
          borderRadius: (props: Props) => {
            if(!props.isFooterEnabled) return '0px 0px 20px 20px';
            else return '';
          },
        },
      },
      '& .MuiDataGrid-cell': {
        outline: 'none !important',
        '& svg': {
          width: '0',
          height: '0',
        },
      },
      '& .MuiDataGrid-actionsCell': {
        '& .MuiButtonBase-root': {
          '& > svg': {
            color: '#5AB8FF',
            width: '24px',
            height: '24px',
            transition: 'color 0.25s',
          },
        },
        '&:hover':{
          '& > svg': {
            color: theme.colors.primaryBlue[500],
          },
        },
      },
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: '#ECECEC !important',
      borderRadius: '0px 0px 20px 20px',
    },
  },
  popper: {
    '& > .MuiPaper-root': {
      '& > .MuiList-root': {
        '& > .MuiMenuItem-root': {
          '& > .MuiListItemIcon-root': {
            '& > svg': {
              color: '#5AB8FF',
              width: '24px',
              height: '24px',
              transition: 'color 0.25s',
            },
          },
          '&:hover':{
            '& > .MuiListItemIcon-root': {
              '& > svg': {
                color: theme.colors.primaryBlue[500],
              },
            },
          },
        },
      },
    },
  },
}));

type DataTableType = {
  className?: any;
  columns: GridColDef[];
  data: any;
  page?: number;
  defaultSorting?: any[];
  visibleRows?: number[];
  autoHeight?: boolean;
  autoPageSize?: boolean;
  columnHeaderHeight?: number,
  rowHeight?: number,
  density?: 'comfortable' | 'compact' | 'standard',
  isAutosizable?: boolean;
  isCheckable?: boolean;
  isColumnFilterable?: boolean;
  isColumnMenuEnabled?: boolean;
  isColumnSelectable?: boolean;
  isColumnSortable?: boolean;
  isColumnResizable?: boolean;
  isDensitySelectable?: boolean;
  isFooterEnabled?: boolean;
  isFooterPaginationEnabled?: boolean;
  isFooterSelectedRowCountEnabled?: boolean;
  isLoading?: boolean;
  isShowCellVerticalBorder?: boolean;
  isShowColumnVerticalBorder?: boolean;
  isRowSelectable?: boolean;
  isClickRowSelectable?: boolean;
  isMultipleRowSelectable?: boolean;
  onCellClick?: any;
  onCellDoubleClick?: any;
  onColumnHeaderClick?: any;
  onColumnHeaderDoubleClick?: any;
  onColumnHeaderEnter?: any;
  onColumnHeaderLeave?: any;
  onColumnHeaderOut?: any;
  onColumnHeaderOver?: any;
  onColumnOrderChange?: any;
  onColumnResize?: any;
  onColumnWidthChange?: any;
  onDensityChange?: any;
  onMenuClose?: any;
  onMenuOpen?: any;
  onPaginationModelChange?: any;
  onPreferencePanelClose?: any;
  onPreferencePanelOpen?: any;
  onResize?: any;
  onRowClick?: any;
  onRowContextMenu?: any;
  onRowCountChange?: any;
  onRowDoubleClick?: any;
  noResultsText?: any;
};

type SupportedDataGridLocales = keyof typeof dataGridLocales;

const DataTable: React.FunctionComponent<DataTableType> = ({ className, columns, data, autoHeight = false, autoPageSize = false, page = 0, defaultSorting = [], visibleRows = [5], columnHeaderHeight = 42, rowHeight = 52, density = 'comfortable', isAutosizable, isCheckable, isColumnFilterable, isColumnMenuEnabled, isColumnSelectable, isColumnSortable, isColumnResizable, isDensitySelectable, isFooterEnabled, isFooterPaginationEnabled, isFooterSelectedRowCountEnabled, isLoading = false, isShowCellVerticalBorder, isShowColumnVerticalBorder, isRowSelectable, isClickRowSelectable, isMultipleRowSelectable, onCellClick, onCellDoubleClick, onColumnHeaderClick, onColumnHeaderDoubleClick, onColumnHeaderEnter, onColumnHeaderLeave, onColumnHeaderOut, onColumnHeaderOver, onColumnOrderChange, onColumnResize, onColumnWidthChange, onDensityChange, onMenuClose, onMenuOpen, onPaginationModelChange, onPreferencePanelClose, onPreferencePanelOpen, onResize, onRowClick, onRowContextMenu, onRowCountChange, onRowDoubleClick, noResultsText, }) => {
  
  const classes = useStyles({
    columnHeaderHeight: columnHeaderHeight,
    isFooterEnabled: isFooterEnabled,
  });

  const paginationModel = { page: page, pageSize: visibleRows[0] };

  const [state, setState] = useStates({
    autoHeight: autoHeight,
    density: density,
    lastPageSize: paginationModel.pageSize,
  });

  const handleResizeRef = useRef(false);

  const languageData = useAppSelector((state: any) => state.language);
  const language = languageData.language;
  const getMuiLanguage: any = getMuiLanguageCode(language, dataGridLocales);
  const muiDataGridLanguage: SupportedDataGridLocales = getMuiLanguage;

  const gridColumns: GridColDef[] = columns.map((item: any) => {
    return {...item, flex: item.flex ? item.flex : 1};
  }).filter((item: any) => item !== null);

  const MemoizedRowComponent = React.memo((props) => {
    return <GridRow {...props} />;
  });

  const handleOnDensityChange = (e: any) => {
    setState("density", e);
    handleResizeRef.current = true;
    if(onDensityChange) {
      onDensityChange(e);
    }
  };

  const handlePaginationChange = (e: any) => {
    if(e.pageSize !== state.lastPageSize) {
      handleResizeRef.current = true;
      setState("lastPageSize", e.pageSize);
    }
    if(onPaginationModelChange) {
      onPaginationModelChange(e);
    }
  };

  const handleOnResize = (e: any) => {
    if(handleResizeRef.current === true) {
      handleAutoResize();
    }
    if(onResize) {
      onResize(e);
    }
  };

  const handleAutoResize = () => {
    handleResizeRef.current = false;
    setState("autoHeight", true);
    setTimeout(() => {
      setState("autoHeight", autoHeight);
    }, 100);
  };

  const DataTableToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarDensitySelector/>
      </GridToolbarContainer>
    );
  };

  return (
    <div className={classes.dataTableWrapper}>
      <DataGrid
        className={`${classes.dataTable} ${className ? className : ''}`}
        rows={data}
        autoHeight={state.autoHeight}
        autoPageSize={autoPageSize}
        columnHeaderHeight={columnHeaderHeight}
        rowHeight={rowHeight}
        density={state.density}
        columns={gridColumns}
        initialState={{ pagination: { paginationModel }, sorting: { sortModel: defaultSorting } }}
        pageSizeOptions={visibleRows}
        checkboxSelection={isCheckable}
        disableAutosize={!isAutosizable}
        disableColumnFilter={!isColumnFilterable}
        disableColumnMenu={!isColumnMenuEnabled}
        disableColumnSelector={!isColumnSelectable}
        disableColumnSorting={!isColumnSortable}
        disableColumnResize={!isColumnResizable}
        disableDensitySelector={!isDensitySelectable}
        disableRowSelectionOnClick={!isClickRowSelectable}
        disableMultipleRowSelection={!isMultipleRowSelectable}
        hideFooter={!isFooterEnabled}
        hideFooterPagination={!isFooterPaginationEnabled}
        hideFooterSelectedRowCount={!isFooterSelectedRowCountEnabled}
        showCellVerticalBorder={isShowCellVerticalBorder}
        showColumnVerticalBorder={isShowColumnVerticalBorder}
        rowSelection={isRowSelectable}
        onCellClick={onCellClick}
        onCellDoubleClick={onCellDoubleClick}
        onColumnHeaderClick={onColumnHeaderClick}
        onColumnHeaderDoubleClick={onColumnHeaderDoubleClick}
        onColumnHeaderEnter={onColumnHeaderEnter}
        onColumnHeaderLeave={onColumnHeaderLeave}
        onColumnHeaderOut={onColumnHeaderOut}
        onColumnHeaderOver={onColumnHeaderOver}
        onColumnOrderChange={onColumnOrderChange}
        onColumnResize={onColumnResize}
        onColumnWidthChange={onColumnWidthChange}
        onDensityChange={handleOnDensityChange}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        onPaginationModelChange={handlePaginationChange}
        onPreferencePanelClose={onPreferencePanelClose}
        onPreferencePanelOpen={onPreferencePanelOpen}
        onResize={handleOnResize}
        onRowClick={onRowClick}
        onRowCountChange={onRowCountChange}
        onRowDoubleClick={onRowDoubleClick}
        loading={isLoading}
        localeText={{...dataGridLocales[muiDataGridLanguage].components.MuiDataGrid.defaultProps.localeText, noRowsLabel: noResultsText }}
        slots={{
          row: MemoizedRowComponent,
          toolbar: DataTableToolbar,
        }}
        slotProps={{
          row: {
            onContextMenu: (e) => onRowContextMenu(e),
          },
          basePopper: {
            className: classes.popper,
          },
        }}
      />
    </div>
  );
}

export default DataTable;