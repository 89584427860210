import EmojiIcon from 'src/components/Icons/EmojiIcon';
import NormalButton from 'src/components/Buttons/NormalButton';
import Popper from 'src/components/Layouts/Popper';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isKey } from 'src/utils/useFunctions';
import { setComments } from 'src/store/actions/comments.actions';
import { setGalleryMedias } from 'src/store/actions/gallery.actions';
import { setTimelineMedias, setTimelinePosts } from 'src/store/actions/timeline.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import moment from 'src/utils/moment';
import ReactionsPopperList from '../PopperList';

const useStyles = createUseStyles((theme: any) => ({
  reactionsPopperWrapper: {
    backgroundColor: theme.colors.white,
    borderRadius: '14px',
    boxShadow: theme.shadows[3],
    display: 'flex',
    fontSize: '22px',
    gap: '8px',
    padding: '6px 8px',
    position: 'relative',
    zIndex: theme.zIndex.popperWrapper,
  },
  reactionsWrapper: {
    position: 'relative',
  },
}));

type ReactionsPopperType = {
  data: any;
  ID: any;
  type: 'post' | 'media' | 'comment';
  date?: any;
  reactionButtonType?: 'secondary' | 'reacted' | 'text',
  reactionButtonTypeActive?: 'secondary' | 'reacted' | 'text',
  className?: any;
  classNameReactionButton?: any;
};

const ReactionsPopper: React.FunctionComponent<ReactionsPopperType> = ({ data, ID, type, date, reactionButtonType = 'secondary', reactionButtonTypeActive = 'reacted', className, classNameReactionButton }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const commentsData = useAppSelector((state: any) => state.comments);
  const configurationData = useAppSelector((state: any) => state.configuration);
  const mediasData = useAppSelector((state: any) => state.gallery).medias;
  const timelineData = useAppSelector((state: any) => state.timeline);
  const reactions = useMemo(() => configurationData.configuration.reactions, [configurationData.configuration.reactions]);
  const reactionsWrapper: any = useRef(null);
  const reactionsPopperVisible: any = useRef(false);
  const reactionsPopperButton: any = useRef(null);
  const reactionsService = useAppSelector((state: any) => state.services).reactionsService;

  const [state, setState] = useStates({
    reactionPopperAnchor: null,
  });

  const handleReactionsOpen = () => {
    setState("reactionPopperAnchor", reactionsPopperButton.current);
    reactionsPopperVisible.current = true;
  };

  const handleReactionsClose = () => {
    reactionsPopperVisible.current = false;
    setTimeout(() => {
      if(!reactionsPopperVisible.current) {
        setState("reactionPopperAnchor", null);
      }
    }, 500);
  };

  const handleReactionFast = (reactionID: any) => {
    handleEmoji(reactionID);
  };

  const tempUpdatePostReactions = (oldData: any, emojiID: any) => {
    const postData = timelineData.posts.filter((post: any) => post.uniqueID === ID).length === 0 ? 0 : timelineData.posts.find((post: any) => post.uniqueID === ID);
    const postID = postData.postID;
    if(oldData.myEmojiID === emojiID) {
      const newData = timelineData.posts.map((post: any) => {
        if(post.postID === postID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          newTotals.sort((a: any, b: any) => { return b.total - a.total });
          return { ...post, reactions: {...post.reactions, myEmojiID: 0, totals: newTotals} };
        } else {
          return post;
        }
      });
      dispatch(setTimelinePosts(newData));
    } else {
      const newData = timelineData.posts.map((post: any) => {
        if(post.postID === postID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals + 1};
            } else if(item.emojiID === oldData.myEmojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          newTotals.sort((a: any, b: any) => { return b.total - a.total });
          return { ...post, reactions: {...post.reactions, myEmojiID: emojiID, totals: newTotals} };
        } else {
          return post;
        }
      });
      dispatch(setTimelinePosts(newData));
    }
  };

  const updatePostReactions = (reactionsData: any) => {
    const postData = timelineData.posts.filter((post: any) => post.uniqueID === ID).length === 0 ? 0 : timelineData.posts.find((post: any) => post.uniqueID === ID);
    const postID = postData.postID;
    const newTimelineData = timelineData.posts.map((post: any) => {
      if(post.postID === postID) {
        return { ...post, reactions: {...post.reactions, myEmojiID: reactionsData.myEmojiID, totals: reactionsData.totals} };
      } else {
        return post;
      }
    });
    dispatch(setTimelinePosts(newTimelineData));
  };

  const tempUpdateMediaReactions = (oldData: any, emojiID: any) => {
    if(oldData.myEmojiID === emojiID) {
      const newTimelineMediasList = timelineData.medias.map((newMedia: any) => { 
        if(newMedia.mediaID === ID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          return {...newMedia, reactions: {...newMedia.reactions, myEmojiID: 0, totals: newTotals}};
        } else {
          return newMedia;
        }
      });
      dispatch(setTimelineMedias(newTimelineMediasList));
      const newItems = mediasData.map((dates: any) => {
        if(moment(dates.date).format("YYYY-MM") === moment(date).format("YYYY-MM")) {
          return {...dates, medias: dates.medias.map((media: any) => {
            if(media.mediaID === ID) {
              const newTotals = reactions.map((item: any) => {
                const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
                if(item.emojiID === emojiID) {
                  return { emojiID: item.emojiID, total: totals - 1};
                } else {
                  return { emojiID: item.emojiID, total: totals};
                }
              }).filter((item: any) => item.total !== 0);
              return {...media, reactions: {...media.reactions, myEmojiID: 0, totals: newTotals} };
            } else {
              return media;
            }
          })};
        } else {
          return dates;
        }
      });
      dispatch(setGalleryMedias(newItems));
    } else {
      const newTimelineMediasList = timelineData.medias.map((newMedia: any) => { 
        if(newMedia.mediaID === ID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals + 1};
            } else if(item.emojiID === oldData.myEmojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          return {...newMedia, reactions: {...newMedia.reactions, myEmojiID: emojiID, totals: newTotals}};
        } else {
          return newMedia;
        }
      });
      dispatch(setTimelineMedias(newTimelineMediasList));
      const newItems = mediasData.map((dates: any) => {
        if(moment(dates.date).format("YYYY-MM") === moment(date).format("YYYY-MM")) {
          return {...dates, medias: dates.medias.map((media: any) => {
            if(media.mediaID === ID) {
              const newTotals = reactions.map((item: any) => {
                const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
                if(item.emojiID === emojiID) {
                  return { emojiID: item.emojiID, total: totals + 1};
                } else if(item.emojiID === oldData.myEmojiID) {
                  return { emojiID: item.emojiID, total: totals - 1};
                } else {
                  return { emojiID: item.emojiID, total: totals};
                }
              }).filter((item: any) => item.total !== 0);
              return {...media, reactions: {...media.reactions, myEmojiID: emojiID, totals: newTotals} };
            } else {
              return media;
            }
          })};
        } else {
          return dates;
        }
      });
      dispatch(setGalleryMedias(newItems));
    }
  };

  const updateMediaReactions = (reactionsData: any) => {
    const newTimelineMediasList = timelineData.medias.map((newMedia: any) => { 
      if(newMedia.mediaID === ID) {
        return {...newMedia, reactions: {...newMedia.reactions, myEmojiID: reactionsData.myEmojiID, totals: reactionsData.totals}};
      } else {
        return newMedia;
      }
    });
    dispatch(setTimelineMedias(newTimelineMediasList));
    const newItems = mediasData.map((dates: any) => {
      if(moment(dates.date).format("YYYY-MM") === moment(date).format("YYYY-MM")) {
        return {...dates, medias: dates.medias.map((media: any) => {
          if(media.mediaID === ID) {
            return {...media, reactions: {...media.reactions, myEmojiID: reactionsData.myEmojiID, totals: reactionsData.totals} };
          } else {
            return media;
          }
        })};
      } else {
        return dates;
      }
    });
    dispatch(setGalleryMedias(newItems));
  };

  const tempUpdateCommentReactions = (oldData: any, emojiID: any) => {
    if(oldData.myEmojiID === emojiID) {
      const newData = commentsData.comments.map((comment: any) => {
        if(comment.commentID === ID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          newTotals.sort((a: any, b: any) => { return b.total - a.total });
          return { ...comment, reactions: {...comment.reactions, myEmojiID: 0, totals: newTotals} };
        } else {
          return comment;
        }
      });
      dispatch(setComments(newData));
    } else {
      const newData = commentsData.comments.map((comment: any) => {
        if(comment.commentID === ID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals + 1};
            } else if(item.emojiID === oldData.myEmojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          newTotals.sort((a: any, b: any) => { return b.total - a.total });
          return { ...comment, reactions: {...comment.reactions, myEmojiID: emojiID, totals: newTotals} };
        } else {
          return comment;
        }
      });
      dispatch(setComments(newData));
    }
  };

  const updateCommentReactions = (reactionsData: any) => {
    const newTimelineCommentsList = commentsData.comments.map((newComment: any) => { 
      if(newComment.commentID === ID) {
        return {...newComment, reactions: {...newComment.reactions, myEmojiID: reactionsData.myEmojiID, totals: reactionsData.totals}};
      } else {
        return newComment;
      }
    });
    dispatch(setComments(newTimelineCommentsList));
  };

  const handleOnReaction = () => {
    setState("reactionPopperAnchor", null);
  };
  
  const handleEmoji = (emojiID: any) => {
    if(type === 'post') {
      const oldData = data.reactions;
      tempUpdatePostReactions(oldData, emojiID);
      const postData = timelineData.posts.filter((post: any) => post.uniqueID === ID).length === 0 ? 0 : timelineData.posts.find((post: any) => post.uniqueID === ID);
      const postID = postData.postID;
      if(oldData.myEmojiID === emojiID) {
        reactionsService && reactionsService.deleteReaction(postID, "post").then((result: any) => {
          if(result.status === 200) {
            updatePostReactions(result.data);
            createNotification(t("reaction_deleted"), "success");
            setState("reactionPopperAnchor", null);
          } else {
            createNotification(t("reaction_not_deleted"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      } else {
        const payload = {
          postID: postID,
          emojiID: emojiID,
        };
        reactionsService && reactionsService.handleReaction(payload).then((result: any) => {
          if(result.status === 200) {
            updatePostReactions(result.data);
            createNotification(t("reaction_saved"), "success");
            setState("reactionPopperAnchor", null);
          } else {
            createNotification(t("reaction_not_saved"), "error");
            dispatch(setTimelinePosts(oldData));
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
          dispatch(setTimelinePosts(oldData));
        });
      }
    } else if(type === 'media') {
      const oldData = data.reactions;
      tempUpdateMediaReactions(oldData, emojiID);
      if(data.reactions.myEmojiID === emojiID) {
        reactionsService && reactionsService.deleteReaction(ID, "media").then((result: any) => {
          if(result.status === 200) {
            updateMediaReactions(result.data);
            createNotification(t("reaction_deleted"), "success");
            setState("reactionPopperAnchor", null);
          } else {
            createNotification(t("reaction_not_deleted"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      } else {
        const payload = {
          mediaID: ID,
          emojiID: emojiID,
        };
        reactionsService && reactionsService.handleReaction(payload).then((result: any) => {
          if(result.status === 200) {
            updateMediaReactions(result.data);
            createNotification(t("reaction_saved"), "success");
            setState("reactionPopperAnchor", null);
          } else {
            createNotification(t("reaction_not_saved"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      }
    } else if(type === 'comment') {
      const oldData = data.reactions;
      tempUpdateCommentReactions(oldData, emojiID);
      if(data.reactions.myEmojiID === emojiID) {
        reactionsService && reactionsService.deleteReaction(data.commentID, "comment").then((result: any) => {
          if(result.status === 200) {
            updateCommentReactions(result.data);
            createNotification(t("reaction_deleted"), "success");
            setState("reactionPopperAnchor", null);
          } else {
            createNotification(t("reaction_not_deleted"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      } else {
        const payload = {
          commentID: data.commentID,
          emojiID: emojiID,
        };
        reactionsService && reactionsService.handleReaction(payload).then((result: any) => {
          if(result.status === 200) {
            updateCommentReactions(result.data);
            createNotification(t("reaction_saved"), "success");
            setState("reactionPopperAnchor", null);
          } else {
            createNotification(t("reaction_not_saved"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      }
    }
  };
  
  return (
    <div className={`${classes.reactionsWrapper} ${className ? className : ''}`} ref={reactionsWrapper}>
      <Popper className={classes.reactionsPopperWrapper} open={Boolean(state.reactionPopperAnchor)} anchorEl={state.reactionPopperAnchor} container={reactionsWrapper.current} placement='top-start' transition={true} transitionEffect='grow'>
        <div onMouseEnter={handleReactionsOpen} onMouseLeave={handleReactionsClose}>
          <ReactionsPopperList data={data} ID={ID} type={type} date={date} onReaction={handleOnReaction}/>
        </div>
      </Popper>
      <NormalButton className={`${classNameReactionButton} ${(data.reactions.myEmojiID && data.reactions.myEmojiID !== 0) ? 'active' : ''}`} buttonType={(data.reactions.myEmojiID && data.reactions.myEmojiID !== 0) ? reactionButtonTypeActive : reactionButtonType} startIcon={(!data.reactions.myEmojiID || data.reactions.myEmojiID === 0) ? (<SVG src="thumbs-up"/>) : (<EmojiIcon emoji={reactions.find((reaction: any) => reaction.emojiID === data.reactions.myEmojiID).emoji} size={16}/>)} onClick={() => handleReactionFast((!data.reactions.myEmojiID || data.reactions.myEmojiID === 0) ? reactions[0].emojiID : data.reactions.myEmojiID)} onLongPress={handleReactionsOpen} onMouseEnter={handleReactionsOpen} onMouseLeave={handleReactionsClose} customRef={reactionsPopperButton}>
        {(!data.reactions.myEmojiID || data.reactions.myEmojiID === 0) ? t('reactions_action') : reactions.find((reaction: any) => reaction.emojiID === data.reactions.myEmojiID).name}
      </NormalButton>
    </div>
  );
}

export default ReactionsPopper;