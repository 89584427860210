import AddonsSettings from './components/AddonsSettings';
import CookiesSettings from './components/CookiesSettings';
import CustomizationSettings from './components/CustomizationSettings';
import DataManagementSettings from './components/DataManagementSettings';
import EmployeeSettings from './components/EmployeeSettings';
import NotificationsSettings from './components/NotificationsSettings';
import PremiumSettings from './components/PremiumSettings';
import PrivacySettings from './components/PrivacySettings';
import ProfileSettings from './components/ProfileSettings';
import React from 'react';
import SecuritySettings from './components/SecuritySettings';
import WagesSettings from './components/WagesSettings';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { setCurrentMenu } from 'src/store/actions/layout.actions';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  settingsPage: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    justifyContent: 'center',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  tabsWrapper: {
    display: 'flex',
    flex: '0 0 15%',
  },
  tabsMenuWrapper: {
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    marginTop: '16px',
  },
  tabsMenu: {
    height: 'fit-content',
  },
  tabsMenuBar: {
    backgroundColor: theme.colors.grey[200],
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: '1 1 100%',
  },
  wrapperColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 240px',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  nothingFoundWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  nothingFound: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(60% - 68px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tab } = useParams();
  const classes = useStyles();
  
  useEffect(() => {
    const url = location.pathname.replace("/settings/", "");
    if(url === "/settings") {
      navigate("/settings/profile");
    }
  }, [tab, location.pathname, navigate], [tab]);

  useEffect(() => {
    dispatch(setCurrentMenu("settings"));
    return () => {
      dispatch(setCurrentMenu("main"));
    };
  }, [dispatch], []);
  
  return (
    <div className={classes.settingsPage}>
      <div className={classes.wrapper}>
        <div className={classes.wrapperColumn}>
          <div className={classes.settingsWrapper}>
            {
              (tab === "" || tab === undefined) ? (
                <div className={classes.loading}>
                  <div className={classes.spinner}>
                    <CircularProgress/>
                  </div>
                </div>
              ) : tab === "profile" ? (
                <ProfileSettings/>
              ) : tab === "security" ? (
                <SecuritySettings/>
              ) : tab === "privacy" ? (
                <PrivacySettings/>
              ) : tab === "premium" ? (
                <PremiumSettings/>
              ) : tab === "employee" ? (
                <EmployeeSettings/>
              ) : tab === "wages" ? (
                <WagesSettings/>
              ) : tab === "notifications" ? (
                <NotificationsSettings/>
              ) : tab === "cookies" ? (
                <CookiesSettings/>
              ) : tab === "customizations" ? (
                <CustomizationSettings/>
              ) : tab === "addons" ? (
                <AddonsSettings/>
              ) : tab === "data-management" ? (
                <DataManagementSettings/>
              ) : (
                <div className={classes.nothingFoundWrapper}>
                  <div className={classes.nothingFound}>
                    {t('nothing_was_found')}
                  </div>  
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;