import appEnvironment from 'src/constants/environment';
import config from '../../constants/config';
import getUserInfo from './modules/UserInfo';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { callFunction } from 'src/utils/useFunctions';
import { getAllCypressTags, getAllCypressTagsNames, hightlightAllCypressTagsNames, hightlightAllCypressTagsNamesKey, isCypress } from 'src/utils/useCypress';
import { modules, statuses } from './config';
import { productFruits } from 'product-fruits';
import { resetClassbook } from 'src/store/actions/classbook.actions';
import { resetFirebaseNotifications } from 'src/store/actions/firebasenotification.actions';
import { resetPostCreateModal } from 'src/store/actions/modals.actions';
import { setDeinitializeUserController, setInitializeUserController, setReinitializeUserController, setResetUserController } from 'src/store/actions/controllers.actions';
import { setIsFailed, setIsHighDemand, setIsLanguageLoaded, setIsUserDataLoaded, setIsUserLoaded, setUserLoadedStatus, updateUserLoadedStatus } from 'src/store/actions/loading.actions';
import { setStockCart } from 'src/store/actions/stock.actions';
import { setUserObject, setUserSettings, setUserStatus } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { getAppApiServer, getAppData, removeAppAccessToken, setAppData } from 'src/utils/useApp';
import { useLocation } from 'react-router';

interface Props {
  children: any
};

const UserController: React.FunctionComponent<Props> = (props: Props) => {

  const dispatch = useAppDispatch();
  const controllersData = useAppSelector((state: any) => state.controllers);
  const languageData = useAppSelector((state: any) => state.language);
  const loadingData = useAppSelector((state: any) => state.loading);
  const userData = useAppSelector((state: any) => state.user);
  const windowHandler: any = window;
  const location = windowHandler.location;
  const appLocation = useLocation();
  const environment = config.APP_ENVIRONMENT;
  const productFruitsCode = appEnvironment[environment].productFruitsCode;

  const initializeUserControllerStatus = controllersData.initializeUserController;
  const deinitializeUserControllerStatus = controllersData.deinitializeUserController;
  const reinitializeUserControllerStatus = controllersData.reinitializeUserController;
  const resetUserControllerStatus = controllersData.resetUserController;

  const controllerModulesRef: any = useRef([]);
  
  const handleFailedLoad = useCallback((text?: any) => {
    windowHandler.isAppReady = false;
    dispatch(setIsHighDemand(false));
    dispatch(setIsLanguageLoaded(false));
    dispatch(setIsUserLoaded(false));
    dispatch(setIsFailed(text)); 
  }, [dispatch, windowHandler]);

  const loadApplication = useCallback(() => {
    setTimeout(() => {
      dispatch(setIsLanguageLoaded(true));
      dispatch(setIsUserLoaded(true));
      dispatch(setIsUserDataLoaded(true));
      dispatch(setUserLoadedStatus(0));
      windowHandler.isAppReady = true;
    }, 500);
  }, [dispatch, windowHandler]);

  const preloadApplication = useCallback(() => {
    setTimeout(() => {
      dispatch(setIsLanguageLoaded(true));
      dispatch(setUserLoadedStatus(0));
      windowHandler.isAppReady = true;
    }, 500);
  }, [dispatch, windowHandler]);

  const getModuleData = (moduleName: any, value: any, defaultValue: any) => {
    const isModuleExist = controllerModulesRef.current.filter((item: any) => item.name === moduleName).length === 1;
    if(isModuleExist) {
      const isModuleDataExist = controllerModulesRef.current.find((item: any) => item.name === moduleName).data;
      if(isModuleDataExist) {
        const moduleData = controllerModulesRef.current.find((item: any) => item.name === moduleName).data;
        return moduleData[value];
      } else {
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  };

  const setModuleStatusAndData = (moduleName: any, status: any, data: any) => {
    controllerModulesRef.current = controllerModulesRef.current.map((item: any) => {
      if(item.name === moduleName) {
        return {...item, status: status, data: data};
      } else { 
        return item;
      }
    });
  };

  const loadModule = useCallback(async (moduleData: any) => {
    if(moduleData.status === statuses[0]) {
      const readyDependencies = moduleData.dependencies.map((dependency: any) => {
        const currentModuleData = controllerModulesRef.current.filter((item: any) => item.name === dependency).length === 1 ? controllerModulesRef.current.find((item: any) => item.name === dependency) : {status: statuses[0]};
        if(currentModuleData.status === statuses[3]) {
          return dependency;
        } else {
          return null;
        }
      }).filter((item: any) => item !== null);
      if((moduleData.dependencies.length === 0 || moduleData.dependencies.length === readyDependencies.length) && moduleData.status === statuses[0]) {
        setModuleStatusAndData(moduleData.name, statuses[1], null);
        const context = {
          dispatch: dispatch,
          windowHandler: windowHandler,
          location: location,
          userData: userData,
          languageData: languageData,
          classesID: getModuleData("user", "classesID", []),
          schoolsID: getModuleData("user", "schoolsID", []),
          classes: getModuleData("classes", "classes", []),
        };
        const result: any = await callFunction(moduleData.func, moduleData.funcData, context);
        if(result) {
          setModuleStatusAndData(moduleData.name, statuses[3], result);
          dispatch(updateUserLoadedStatus((100 / modules.length)));
        } else {
          setModuleStatusAndData(moduleData.name, statuses[2], null);
          handleFailedLoad(moduleData.name);
        }
      }
    }
  }, [dispatch, languageData, location, userData, windowHandler, handleFailedLoad]);

  const loadModules = useCallback(() => {
    const currentModules = controllerModulesRef.current;
    currentModules.forEach((module: any) => {
      loadModule(module);
    });
    setTimeout(() => {
      const currentModules = controllerModulesRef.current;
      if(currentModules.filter((item: any) => item.status === statuses[3]).length !== currentModules.length && currentModules.filter((item: any) => item.status === statuses[2]).length === 0) {
        loadModules();
      }
      if(currentModules.filter((item: any) => item.status === statuses[3]).length === currentModules.length) {
        loadApplication();
      }
    }, 500);
  }, [loadModule, loadApplication]);

  const initializeController = useCallback(() => {
    const isInstantAccessPage = config.APP_INSTANT_ACCESS_PAGES.some((page: any) => location.pathname.includes(page));
    if(userData.userStatus === "loggedIn" && !isInstantAccessPage) {
      const newControllerModules = [...modules];
      controllerModulesRef.current = newControllerModules;
      loadModules();
    } else {
      if(isInstantAccessPage) {
        preloadApplication();
      }
    }
  }, [loadModules, preloadApplication, location.pathname, userData.userStatus]);

  const deinitializeController = useCallback(() => {
    const savedData = getAppData();
    const useApiServers = config.APP_ENVIRONMENT !== "production" && Object.keys(config.API_SERVERS).length;
    const savedUsers = savedData.users ? savedData.users : [];
    const newUsersData = savedUsers.length === 1 ? [] : savedUsers.map((item: any) => { if((useApiServers ? (item.userID === userData.userObject.userID && item.apiServer === getAppApiServer()) : item.userID === userData.userObject.userID)) { return {...item, accessToken: false}; } else { return item; } });
    const newData = {user: {}, users: newUsersData, savedPost: null, notifications: {...savedData.notifications, fcmToken: ""}, twigchat: {...savedData.twigchat, drafts: []}};
    setAppData(newData);
    removeAppAccessToken();
    dispatch(setIsUserLoaded(false));
    dispatch(setIsUserDataLoaded(false));
    dispatch(setUserObject({}));
    dispatch(setUserSettings([]));
    dispatch(setUserStatus("loggedOff"));
    dispatch(setResetUserController(true));
  }, [dispatch, userData.userObject.userID]);

  const reinitializeController = useCallback(() => {
    dispatch(setIsUserLoaded(false));
    dispatch(setIsUserDataLoaded(false));
    dispatch(setUserObject({}));
    dispatch(setUserSettings([]));
    dispatch(setUserStatus("loggedOff"));
    dispatch(setResetUserController(true));
  }, [dispatch]);

  const resetController = useCallback(() => {
    dispatch(resetClassbook());
    dispatch(resetPostCreateModal());
    dispatch(setStockCart([]));
    dispatch(resetFirebaseNotifications());
    dispatch(setIsFailed(false));
  }, [dispatch]);

  useEffect(() => {
    if(initializeUserControllerStatus === true) {
      dispatch(setInitializeUserController(false));
      initializeController();
    }
  }, [dispatch, initializeUserControllerStatus, initializeController], [initializeUserControllerStatus]);

  useEffect(() => {
    if(deinitializeUserControllerStatus === true) {
      dispatch(setDeinitializeUserController(false));
      deinitializeController();
    }
  }, [dispatch, deinitializeUserControllerStatus, deinitializeController], [deinitializeUserControllerStatus]);

  useEffect(() => {
    if(reinitializeUserControllerStatus === true) {
      dispatch(setReinitializeUserController(false));
      reinitializeController();
    }
  }, [dispatch, reinitializeUserControllerStatus, reinitializeController], [reinitializeUserControllerStatus]);

  useEffect(() => {
    if(resetUserControllerStatus === true) {
      dispatch(setResetUserController(false));
      resetController();
    }
  }, [dispatch, resetUserControllerStatus, resetController], [resetUserControllerStatus]);

  useEffect(() => {
    windowHandler.isAppReady = false;
    windowHandler.isAttendanceReady = false;
    windowHandler.isCalendarReady = false;
    windowHandler.isGalleryReady = false;
    if(userData) {
      if(languageData.isFirstLoaded) {
        if(userData.userStatus === "loggedIn") {
          windowHandler.isAppReady = false;
        } else {
          setTimeout(() => {
            windowHandler.isAppReady = true;
          }, 500);
        }
      }
    }
    if(isCypress()) {
      windowHandler.getAllCypressTags = function() {
        return getAllCypressTags();
      };
      windowHandler.getAllCypressTagsNames = function() {
        return getAllCypressTagsNames();
      };
      windowHandler.hightlightAllCypressTagsNames = function() {
        return hightlightAllCypressTagsNames();
      };
      document.addEventListener('keydown', hightlightAllCypressTagsNamesKey, false);
    }
    return () => {
      windowHandler.isAppReady = false;
      windowHandler.isAttendanceReady = false;
      windowHandler.isCalendarReady = false;
      windowHandler.isGalleryReady = false;
      if(isCypress()) {
        windowHandler.getAllCypressTags = undefined;
        windowHandler.getAllCypressTagsNames = undefined;
        windowHandler.hightlightAllCypressTagsNames = undefined;
        document.removeEventListener('keydown', hightlightAllCypressTagsNamesKey, false);
      }
    } 
  }, [userData.userStatus, languageData.isFirstLoaded, userData, windowHandler], [userData.userStatus, languageData.isFirstLoaded]);

  useEffect(() => {
    if(loadingData.isLanguageLoaded && loadingData.isUserLoaded && userData.userStatus === "loggedIn") {
      const userInfo = getUserInfo(userData.userObject, appLocation);
      if(productFruitsCode !== null && windowHandler.productFruitsIsReady) {
        productFruits.safeExec(($productFruits) => {
          $productFruits.push(['updateUserData', userInfo]);
        });
      }
    }
  }, [dispatch, loadingData.isLanguageLoaded, loadingData.isUserLoaded, userData.userStatus, appLocation, userData, productFruitsCode, windowHandler.productFruitsIsReady], [appLocation]);

  useEffect(() => {
    if(loadingData.isLanguageLoaded && userData.userStatus === "loggedIn" && !loadingData.isUserLoaded && !loadingData.isUserDataLoaded) {
      dispatch(setInitializeUserController(true));
    }
  }, [dispatch, loadingData.isLanguageLoaded, loadingData.isUserLoaded, loadingData.isUserDataLoaded, userData.userStatus, appLocation, userData], [appLocation]);
  
  return config.APP_INSTANT_ACCESS_PAGES.some((page: any) => location.pathname.includes(page)) || (userData.userStatus !== "loggedIn" && loadingData.isLanguageLoaded) || (loadingData.isLanguageLoaded && loadingData.isUserLoaded && userData.userStatus === "loggedIn") ? props.children : null;
}

UserController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default UserController;